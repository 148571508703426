import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: Cormorant;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 34px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.secondary};
  margin-top: 100px;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
